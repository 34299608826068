import { createTypes } from "reduxsauce";
import moment from "moment";

export const tableTypes = createTypes(
  `
    SET_DATA_30MIN
    SET_DATA_1D
    SET_DATA_1S
    `,
  { prefix: "DATA/" }
);

const setData30min = data => {
  if (data.previsao) {
    let newData = data.previsao.map(data => {
      return {
        data: moment().format("DD/MM/YYYY"),
        content: data.content,
        previsto: data.previsto || "",
        sombra80H: data.sombra80H || "",
        sombra80L: data.sombra80L || "",
        sombra95H: data.sombra95H || "",
        sombra95L: data.sombra95L || "",
        valorAtual: data.valorAtual || ""
      };
    });
    data = newData;
  }
  return dispatch => {
    dispatch({
      type: tableTypes.SET_DATA_30MIN,
      data
    });
  };
};

const setData1d = data => {
  if (data.previsao) {
    let newData = data.previsao.map(data => {
      return {
        data: moment().format("DD/MM/YYYY"),
        content: data.content,
        previsto: data.previsto || "",
        sombra80H: data.sombra80H || "",
        sombra80L: data.sombra80L || "",
        sombra95H: data.sombra95H || "",
        sombra95L: data.sombra95L || "",
        valorAtual: data.valorAtual || ""
      };
    });
    data = newData;
  }
  return dispatch => {
    dispatch({
      type: tableTypes.SET_DATA_1D,
      data
    });
  };
};

const setData1s = data => {
  if (data.previsao) {
    let newData = data.previsao.map(data => {
      return {
        data: moment().format("DD/MM/YYYY"),
        content: data.content,
        previsto: data.previsto || "",
        sombra80H: data.sombra80H || "",
        sombra80L: data.sombra80L || "",
        sombra95H: data.sombra95H || "",
        sombra95L: data.sombra95L || "",
        valorAtual: data.valorAtual || ""
      };
    });
    data = newData;
  }
  return dispatch => {
    dispatch({
      type: tableTypes.SET_DATA_1S,
      data
    });
  };
};

export const tableActions = {
  setData30min,
  setData1d,
  setData1s
};

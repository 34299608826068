import styled from "styled-components";
import { theme } from "../../assets";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.size < 1024 ? "column" : "row")};
  width: 100%;
  justify-content: space-evenly;
`;

export const ViewContainer = styled.div`
  width: calc(100% - 10px);
  margin: 10px 5px;
`;

export const ViewContentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: ${props => (props.margin ? "5px 0px 0px 0px" : "0px")};
`;

export const HistoricOptions = styled.div`
  display: flex;
  float: right;
  width: 60%;
  justify-content: space-evenly;
`;

export const PrevisionContainer = styled.div`
  width: calc(100% - 10px);
  margin: 10px 5px;
  border-left: 1px solid ${theme.bgBlack};
  border-right: 1px solid ${theme.bgBlack};
  text-align: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const Values = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  white-space: nowrap;
  width: calc(100% - 10px);
  margin: 10px 5px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.center ? "center" : "space-evenly")};
  width: 100%;
`;

export const Evidence = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 30%;
  cursor: pointer;
  margin: 0px 4px;
  :hover {
    transform: scale(1.1);
    transition: all 0.2s;
  }
  background: ${props =>
    props.blue
      ? "linear-gradient(221.5deg, #70EFD8 1.96%, rgba(255, 255, 255, 0) 104.42%), #65D4EF;"
      : props.green
      ? "linear-gradient(221.5deg, rgba(246, 252, 241, 0.6) 1.96%, rgba(140, 233, 48, 0) 104.42%), #28C76F;"
      : props.purple
      ? "linear-gradient(220.11deg, rgba(183, 135, 205, 0.6) 16.37%, rgba(85, 48, 233, 0) 104.42%), #6C55B7;"
      : props.orange
      ? "linear-gradient(221.5deg, rgba(254, 250, 234, 0.6) 1.96%, rgba(255, 254, 251, 0) 104.42%), #FFC96E;"
      : props.red
      ? " linear-gradient(221.5deg, rgba(254, 234, 234, 0.6) 1.96%, rgba(233, 48, 170, 0) 104.42%), #E94E5C;"
      : null};
  ${props => (props.selected ? "border: 0.5px solid #fff" : null)}
`;

export const Label = styled.label`
  color: ${props => (props.color === "true" ? "#fff" : "#ACB5BD")};
  ${props =>
    props.selected
      ? "text-decoration: underline;text-decoration-color: #65d4ef;"
      : "color: #C8C8C8"};
  text-transform: uppercase;
  /* margin: ${props =>
    props.margin ? "0px 0px 8px 0px" : "0px 5px 0px 5px"}; */
  text-align: center;
  margin: 0px 5px 5px 5px;
  cursor: pointer;
`;

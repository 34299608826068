import styled from "styled-components";

export const CloseButtonContainer = styled.div`
  display: flex;
`;

export const CloseButton = styled.img`
  cursor: pointer;
  margin-left: auto;
`;

export const Title = styled.div`
  margin: 25px 40px;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  font-weight: bold;
  color: #acb5bd;
`;

import { theme } from "../../../assets";

const mock = {
  headers: [
    {
      label: "valorAtual",
      key: "valorAtual",
      color: theme.white
    },
    {
      label: "previsto",
      key: "previsto",
      color: theme.blue
    },
    {
      label: "sombra95H",
      key: "sombra95H",
      color: theme.green
    },
    {
      label: "sombra80H",
      key: "sombra80H",
      color: theme.violet
    },
    {
      label: "sombra80L",
      key: "sombra80L",
      color: theme.yellow
    },
    {
      label: "sombra95L",
      key: "sombra95L",
      color: theme.red
    }
  ],
  data: [
    {
      hora: "9h",
      ValorAtual: "4.43",
      previsao: null,
      sombra801: null,
      sombra802: null,
      sombra951: null,
      sombra952: null
    },
    {
      hora: "10h",
      ValorAtual: "4.45",
      previsao: null,
      sombra801: null,
      sombra802: null,
      sombra951: null,
      sombra952: null
    },
    {
      hora: "11h",
      ValorAtual: "4.47",
      previsao: "4.47",
      sombra801: "4.47",
      sombra951: "4.47",
      sombra802: "4.47",
      sombra952: "4.47"
    },
    {
      hora: "12h",
      ValorAtual: null,
      previsao: "4.49",
      sombra801: "4.86",
      sombra951: "4.29",
      sombra802: "4.11",
      sombra952: "4.55"
    },
    {
      hora: "13h",
      ValorAtual: null,
      previsao: "4.67",
      sombra801: "4.46",
      sombra951: "4.19",
      sombra802: "4.21",
      sombra952: "4.95"
    },
    {
      hora: "14h",
      ValorAtual: null,
      previsao: "4.47",
      sombra801: "4.56",
      sombra951: "4.79",
      sombra802: "4.31",
      sombra952: "4.35"
    },
    {
      hora: "15h",
      ValorAtual: null,
      previsao: null,
      sombra801: null,
      sombra802: null,
      sombra951: null,
      sombra952: null
    },
    {
      hora: "16h",
      ValorAtual: null,
      previsao: null,
      sombra801: null,
      sombra802: null,
      sombra951: null,
      sombra952: null
    },
    {
      hora: "17h",
      ValorAtual: null,
      previsao: null,
      sombra801: null,
      sombra802: null,
      sombra951: null,
      sombra952: null
    }
  ]
};

export default mock;

import styled from "styled-components";
import Expand from "react-expand-animated";

export const ToolTip = styled.div`
  display: grid;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
`;

export const TLabel = styled.label`
  line-height: 22px;
`;

export const ValueLabel = styled.label`
  font-weight: bold;
  line-height: 27px;
`;

export const XAxisDiv = styled.div`
  width: calc(100% - 60px);
  margin: 30px;
  height: 30px;
  background: #e9e9e9;
`;

export const XText = styled.text`
  :hover {
    text-decoration: underline;
  }
  fill: #acb5bd;
  font-size: 14px;
`;

export const divTop = styled(Expand)`
  display: flex;
  width: calc(100% - 10px);
  position: relative;
  height: 45px;
  background: #e9e9e9;
  margin: 0px 0px 10px 0px;
  align-content: center;
  padding: 5px;
`;

export const Date = styled.div`
  width: 50%;
  text-align: center;
  margin: auto;
  font-size: 20px;
  line-height: 27px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 130px 0px 130px 0px;
`;

export const Div = styled.div`
  background: #383838;
  height: 60vh;
  z-index: -1;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin: 0px 150px 0px 200px;
`;

export const ErrorMessage = styled.label`
  font-size: 20px;
  color: white;
`;

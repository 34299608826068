export const theme = {
  black: "#000",
  white: "#fff",
  grayish: "rgba(0, 0, 0, 0.1)",
  blackish: "rgba(0, 0, 0, 0.5)",
  green: "#28C76F",
  violet: "#6C55B7",
  blue: "#65D4EF",
  yellow: "#FFC96E",
  red: "#E94E5C",
  bgBlack: "#282828",
  bgDarkGrey: "#383838"
};

export const icons = {
  error: require("./icons/warning.svg"),
  notificacao: require("./icons/Notificacao.svg"),
  person: require("./icons/User.svg"),
  closeButton: require("./icons/Fechar.svg"),
  arrowUp: require("./icons/arrow_up_24px.svg"),
  arrowDown: require("./icons/arrowDown.svg"),
  graficoView: require("./icons/graficoView.svg"),
  listView: require("./icons/listView.svg"),
  check: require("./icons/CheckNota.svg")
};

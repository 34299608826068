import React from "react";
import Modal from "react-modal";
import * as s from "./styled-modal";
import { icons, theme } from "../../assets";

const ModalContainer = props => {
  Modal.setAppElement("body");

  return (
    <Modal
      isOpen={props.isOpen}
      style={customStyles}
      onRequestClose={props.closeModal}
    >
      <s.CloseButtonContainer>
        <s.CloseButton
          id="btn-close-modal"
          src={icons.closeButton}
          onClick={props.closeModal}
        />
      </s.CloseButtonContainer>
      <s.Title>{props.text}</s.Title>
      {props.children}
    </Modal>
  );
};

const customStyles = {
  content: {
    width: "460px",
    maxHeight: "590px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    background: theme.bgDarkGrey
  },
  overlay: {
    backgroundColor: theme.blackish
  }
};

export default ModalContainer;

import { createStore, combineReducers, applyMiddleware } from "redux";
import { graph, table } from "./reducers";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

const reducers = combineReducers({ graph, table });

export const store = createStore(
  reducers,
  applyMiddleware(thunkMiddleware, createLogger())
);

export * from "./actions";

import React from "react";
import { Header, Footer } from "../../components";
import { theme } from "../../assets";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const data = useSelector(state => state.graph);
  const table = useSelector(state => state.table);
  console.log(data);
  console.log(table);
  return (
    <div style={{ background: theme.bgDarkGrey, height: "100%" }}>
      <Header />
      <Footer />
    </div>
  );
};

export default Dashboard;

import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect
} from "react";
import DataTable from "react-data-table-component";
import columns from "./columns";
import { theme, icons } from "../../assets";
import { useSelector } from "react-redux";
import { height } from "dom-helpers";

const Table = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const selected = useSelector(state => state.graph.selected);
  const data30min = useSelector(state => state.table.data30min);
  const data1d = useSelector(state => state.table.data1d);
  const data1s = useSelector(state => state.table.data1s);
  const Update = useSelector(state => state.graph.graphUpdate);
  const [size, setSize] = useState(window.screen.width <= 1366 ? "30%" : "40%");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    forceUpdate();
  }, [Update]);

  const updateSize = () => {
    if (window.screen.width <= 1366) {
      setSize("55%");
    } else {
      setSize("70%");
    }
  };

  useLayoutEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div>
      <DataTable
        id="table-previsao-dolar"
        style={{
          zIndex: 0,
          margin: "10px 20px 0px 20px",
          width: props.width || "calc(100vw - 40px)",
          overflowX: window.width > 920 ? "hidden" : "auto",
          overflowY: "hidden",
          border: !loading && `1px solid ${theme.grayish}`,
          borderRight: !loading && "0px",
          borderTop: !loading && "0px"
        }}
        noHeader
        columns={columns}
        data={
          selected === "30min" ? data30min : selected === "1D" ? data1d : data1s
        }
        fixedHeader
        responsive
        persistTableHead
        theme="dark"
        progressPending={loading}
        progressComponent={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              fontSize: 20,
              height: 200
            }}
          >
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        }
        noDataComponent={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              fontSize: 20,
              height: 200
            }}
          >
            <img
              src={icons.error}
              style={{ position: "absolute", zIndex: 0, opacity: 0.2 }}
              alt="error"
            />
            <label>{error}</label>
          </div>
        }
      />
    </div>
  );
};

export default Table;

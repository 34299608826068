import React from "react";
import { ModalContainer, Button } from "../../index";
import * as s from "./styled-logout";

const LogoutModal = props => {
  return (
    <ModalContainer
      isOpen={props.isOpen}
      closeModal={props.closeModal}
      text={"você está fazendo logout na plataforma alg os!"}
    >
      <s.LabelModal className="f16">Deseja realmente sair?</s.LabelModal>
      <s.LogOutButtonContainer>
        <Button
          id="logout-sim"
          text={"Sim"}
          border={true}
          color={"white"}
          filled={"blue"}
          onClick={() => {}}
        />
        <Button
          id="logout-nao"
          text={"Não"}
          onClick={() => props.closeModal()}
          color={"red"}
          border="red"
        />
      </s.LogOutButtonContainer>
    </ModalContainer>
  );
};

export default LogoutModal;

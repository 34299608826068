import styled from "styled-components";
import { theme } from "../../assets";

export const Button = styled.button`
  margin: 5px;
  outline: none;
  box-shadow: none;
  border-style: none;
  border: ${props =>
    props.border === "blue"
      ? "1.5px solid #65d4ef"
      : props.border === "red"
      ? `1.5px solid ${theme.red}`
      : null};
  display: flex;
  height: 30px;
  width: 140px;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.filled === "blue"
      ? theme.blue
      : props.filled === "red"
      ? theme.red
      : theme.bgDarkGrey};
  border-radius: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  :hover {
    transform: scale(1.02);
    transition: all 0.5s;
  }
  color: ${props =>
    props.color === "red"
      ? "#E94E5C"
      : props.color === "blue"
      ? "#65d4ef"
      : "#fff"};
  cursor: pointer;
  ${props => (props.disabled ? "cursor: not-allowed" : null)}
`;

export const LoaderContainer = styled.div`
  margin: 10px;
`;

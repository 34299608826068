import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect
} from "react";
import * as s from "./styled-grafico";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // Brush,
  Area,
  ComposedChart
} from "recharts";
import { mock } from "./mock/index";
import { Loader } from "../index";
import { useSelector } from "react-redux";
import moment from "moment";
import { theme } from "../../assets";

const Grafico = () => {
  moment.locale("pt-br");
  const evidence = useSelector(state => state.graph.evidence);
  const selected = useSelector(state => state.graph.selected);
  const graphData30min = useSelector(state => state.graph.graphData30min);
  const graphData1d = useSelector(state => state.graph.graphData1d);
  const graphData1s = useSelector(state => state.graph.graphData1s);
  const Update = useSelector(state => state.graph.graphUpdate);
  // const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState(window.screen.width <= 1366 ? "55%" : "70%");
  const [color, setColor] = useState("#65D4EF");
  const [selectedKey, setSelectedKey] = useState("previsto");
  const [isLoading, setIsLoading] = useState(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const headers = mock.headers;

  useEffect(() => {
    if (evidence === 0) {
      setColor("#65D4EF");
      setSelectedKey("previsto");
    } else if (evidence === 1) {
      setColor("#28C76F");
      setSelectedKey("sombra95H");
    } else if (evidence === 2) {
      setColor("#6C55B7");
      setSelectedKey("sombra80H");
    } else if (evidence === 3) {
      setColor("#FFC96E");
      setSelectedKey("sombra80L");
    } else if (evidence === 4) {
      setColor("#E94E5C");
      setSelectedKey("sombra95L");
    }
  }, [evidence]);

  const updateSize = () => {
    if (window.screen.width === 1366) {
      setSize("58%");
    } else if (window.screen.width > 1366) {
      setSize("70%");
    } else if (window.screen.width >= 1024 && window.screen.width < 1366) {
      setSize("70%");
    } else {
      setSize("55%");
    }
  };

  useLayoutEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    forceUpdate();
  }, [Update]);

  const CustomToolTip = ({ active, payload, label }) => {
    if (active) {
      return (
        <s.ToolTip>
          <s.TLabel className="f16">Cotação</s.TLabel>
          <s.ValueLabel className="f16">{`${(payload[evidence] &&
            payload[evidence].value) ||
            (payload[0] && payload[0].value)}`}</s.ValueLabel>
          <s.TLabel className="f16">{`${label}`}</s.TLabel>
          {selected !== "1S" ? (
            <s.TLabel>{moment().format("DD/MM")}</s.TLabel>
          ) : null}
        </s.ToolTip>
      );
    }
    return null;
  };

  const CustomXAxis = props => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <s.XText x={0} y={0} dy={16} textAnchor="middle">
          {payload.value}
        </s.XText>
      </g>
    );
  };
  if (!isLoading) {
    if (graphData30min.previsao) {
      return (
        <>
          {/* <s.Div> */}
          <ResponsiveContainer height={size}>
            <ComposedChart
              background={theme.bgDarkGrey}
              hideTooltips={true}
              data={
                selected === "30min"
                  ? graphData30min.previsao
                  : selected === "1D"
                  ? graphData1d.previsao
                  : graphData1s.previsao.slice(0, 6)
              }
              margin={{
                top: 5,
                right: 20,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="#484848" strokeWidth={0.1} />
              <XAxis
                dataKey="content"
                tick={<CustomXAxis />}
                tickMargin={5}
                allowDuplicatedCategory={false}
                stroke="white"
              />
              <YAxis
                tick={{ fill: "#acb5bd", fontSize: "14px" }}
                tickLine={false}
                tickCount={10}
                type="number"
                domain={["auto", "auto "]}
                orientation="right"
                stroke="white"
              />
              <defs>
                <linearGradient id="colorPrevisao" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="50%" stopColor={color} stopOpacity={1} />
                  <stop offset="100%" stopColor={color} stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="ValorAtual" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="50%" stopColor={"#000"} stopOpacity={1} />
                  <stop offset="100%" stopColor={"#000"} stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <Tooltip content={<CustomToolTip />} />

              {headers.map((data, index) => {
                if (data.key === selectedKey) {
                  return (
                    <Area
                      key={index}
                      type="linear"
                      dataKey={data.key}
                      stroke={data.color}
                      fillOpacity={0.3}
                      fill="url(#colorPrevisao)"
                      activeDot={{ r: 6 }}
                      strokeWidth={2}
                    />
                  );
                }
                if (data.key === "valorAtual") {
                  return (
                    <Area
                      key={index}
                      type="linear"
                      dataKey={"valorAtual"}
                      stroke={"#fff"}
                      fillOpacity={0.1}
                      fill="url(#ValorAtual)"
                      activeDot={{ r: 6 }}
                      strokeWidth={2}
                    />
                  );
                }
                return (
                  <Line
                    dot={false}
                    key={index + data}
                    type="linear"
                    dataKey={data.key}
                    stroke={data.color}
                    activeDot={{ r: 0 }}
                  />
                );
              })}

              {/* {isOpen && (
                  <Brush
                    dataKey="content"
                    height={20}
                    stroke="#000000"
                    startIndex={0}
                    endIndex={7}
                  ></Brush>
                )} */}
            </ComposedChart>
          </ResponsiveContainer>
          {/* </s.Div> */}
        </>
      );
    } else {
      return (
        <s.ErrorMessageContainer>
          <s.ErrorMessage>
            Não foi possível trazer os dados no momento
          </s.ErrorMessage>
        </s.ErrorMessageContainer>
      );
    }
  } else {
    return (
      <s.LoaderContainer>
        <Loader />
      </s.LoaderContainer>
    );
  }
};

export default Grafico;

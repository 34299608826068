import app from "firebase/app";
import "firebase/firestore";
import { store } from "../store";
import { graphActions, tableActions } from "../store/actions";
// import data from "./credential-data.json";

const firebaseConfig = {
  apiKey: "AIzaSyBJl642_j_uky4FQk7GwJ95Az0-GwnXggA",
  authDomain: "previsao-dolar.firebaseapp.com",
  databaseURL: "https://previsao-dolar.firebaseio.com",
  projectId: "previsao-dolar",
  storageBucket: "previsao-dolar.appspot.com",
  messagingSenderId: "972686855267",
  appId: "1:972686855267:web:b02274f4160190edf7bc56",
  measurementId: "G-WBM0T1LYN2"
  // credential: data
};

const firebaseApp = app.initializeApp(firebaseConfig);
export const firestore = firebaseApp.firestore();

// const CheckDoc = () => {
//   const selected = useSelector(state => state.graph.selected);
//   console.log("-=-=-=-=-", selected);
//   if (selected === "30min") {
//     return "minuto";
//   } else if (selected === "1D") {
//     return "hora";
//   } else if (selected === "1S") {
//     return "dia";
//   }
// };

// -------------------- GET ---------------------
const getPrevisao30minRealtime = () => {
  firestore
    .collection(`previsao`)
    .doc("minuto")
    .onSnapshot(snapshot => {
      store.dispatch(graphActions.setGraphData30min(snapshot.data()));
      store.dispatch(tableActions.setData30min(snapshot.data()));
      store.dispatch(graphActions.setUpdate(1));
    });
};

const getPrevisao1dRealtime = () => {
  firestore
    .collection(`previsao`)
    .doc("hora")
    .onSnapshot(snapshot => {
      console.log("snapshot.doc.data()", snapshot.data());
      store.dispatch(graphActions.setGraphData1d(snapshot.data()));
      store.dispatch(tableActions.setData1d(snapshot.data()));
      store.dispatch(graphActions.setUpdate(1));
    });
};

const getPrevisao1sRealtime = () => {
  firestore
    .collection(`previsao`)
    .doc("dia")
    .onSnapshot(snapshot => {
      console.log("snapshot.doc.data()", snapshot.data());
      store.dispatch(graphActions.setGraphData1s(snapshot.data()));
      store.dispatch(tableActions.setData1s(snapshot.data()));
      store.dispatch(graphActions.setUpdate(1));
    });
};

export const firebase = {
  app: firebaseApp,
  getPrevisao30minRealtime,
  getPrevisao1dRealtime,
  getPrevisao1sRealtime
};

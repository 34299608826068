import { createTypes } from "reduxsauce";

export const graphTypes = createTypes(
  `
SET_GRAPH_DATA_30MIN
SET_GRAPH_DATA_1D
SET_GRAPH_DATA_1S
SET_UPDATE
SET_SELECTED
SET_EVIDENCE
`,
  { prefix: "DATA/" }
);

const setGraphData30min = data => {
  return async dispatch => {
    dispatch({
      type: graphTypes.SET_GRAPH_DATA_30MIN,
      data
    });
  };
};

const setGraphData1d = data => {
  return async dispatch => {
    dispatch({
      type: graphTypes.SET_GRAPH_DATA_1D,
      data
    });
  };
};

const setGraphData1s = data => {
  return async dispatch => {
    dispatch({
      type: graphTypes.SET_GRAPH_DATA_1S,
      data
    });
  };
};

const setUpdate = data => {
  return dispatch => {
    dispatch({
      type: graphTypes.SET_UPDATE,
      data
    });
  };
};

const setSelected = data => {
  return async dispatch => {
    dispatch({
      type: graphTypes.SET_SELECTED,
      data
    });
  };
};

const setEvidence = data => {
  return dispatch => {
    dispatch({
      type: graphTypes.SET_EVIDENCE,
      data
    });
  };
};

export const graphActions = {
  setGraphData30min,
  setGraphData1d,
  setGraphData1s,
  setUpdate,
  setSelected,
  setEvidence
};

import { tableTypes } from "../actions";

const INITIAL_STATE = {
  data30min: [],
  data1d: [],
  data1s: []
};

export const table = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case tableTypes.SET_DATA_30MIN:
      return {
        ...state,
        data30min: action.data
      };
    case tableTypes.SET_DATA_1D:
      return {
        ...state,
        data1d: action.data
      };
    case tableTypes.SET_DATA_1S:
      return {
        ...state,
        data1s: action.data
      };
    default:
      return state;
  }
};

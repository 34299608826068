import { graphTypes } from "../actions";

const INITIAL_STATE = {
  graphData30min: [],
  graphData1d: [],
  graphData1s: [],
  graphUpdate: 0,
  selected: "",
  evidence: 0
};

export const graph = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case graphTypes.SET_GRAPH_DATA_30MIN:
      return {
        ...state,
        graphData30min: action.data
      };
    case graphTypes.SET_GRAPH_DATA_1D:
      return {
        ...state,
        graphData1d: action.data
      };
    case graphTypes.SET_GRAPH_DATA_1S:
      return {
        ...state,
        graphData1s: action.data
      };
    case graphTypes.SET_UPDATE:
      return {
        ...state,
        graphUpdate: action.data
      };
    case graphTypes.SET_SELECTED: {
      return {
        ...state,
        selected: action.data
      };
    }
    case graphTypes.SET_EVIDENCE: {
      return {
        ...state,
        evidence: action.data
      };
    }
    default:
      return state;
  }
};

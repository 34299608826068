import styled from "styled-components";
import { theme } from "../../../assets";

export const NotaContainer = styled.div`
  display: flex;
  margin: 20px 60px;
  justify-content: space-around;
`;

export const Nota = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 0px;
  color: #acb5bd;
  border-radius: 5px;
  border-color: #acb5bd;
  :hover {
    background: #454545;
    transition: all 0.2s;
  }
`;

export const LabelModal = styled.div`
  text-align: center;
  margin: 5px;
  color: #acb5bd;
`;

export const TextArea = styled.textarea`
  justify-content: center;
  border: 1px solid #000000;
  border-radius: 10px;
  outline: none;
  background: #ffffff;
  width: 340px;
  margin: 5px 0px 5px 55px;
  background-color: ${theme.bgDarkGrey};
  border-color: #acb5bd;
  color: #fff;
`;

export const ButtonModal = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px auto;
`;

export const CheckNota = styled.img`
  align-content: center;
  width: 35px;
  height: 35px;
  position: absolute;
`;

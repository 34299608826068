import React from "react";
import * as s from "./styled-button";
import Loader from "../Loader/Loader";

const Button = props => {
  return (
    <s.Button
      id="component-button"
      red={props.red}
      disabled={props.disabled}
      onClick={() => props.onClick()}
      color={props.color}
      border={props.border}
      filled={props.filled}
    >
      {props.loading ? (
        <s.LoaderContainer>
          <Loader />
        </s.LoaderContainer>
      ) : (
        props.text
      )}
    </s.Button>
  );
};

export default Button;

import React, { useState } from "react";
import { ModalContainer, Button } from "../../index";
import * as s from "./styled-alerta";

const AlertaModal = props => {
  const [sucess, setSucess] = useState(false);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  const formatValor = numero => {
    numero = numero.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return numero;
  };

  const Disable = e => {
    if (e === "") {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };

  if (!sucess) {
    return (
      <ModalContainer
        isOpen={props.isOpen}
        closeModal={props.closeModal}
        text={
          "a gente te avisa quando a previsão do dólar estiver dentro do esperado!"
        }
      >
        <s.InputContainer>
          <s.LabelModal className="f16">
            Avise-me quando o Dólar estiver entre
            <s.Input
              id="fisrt-value-input-dolar"
              className="f16"
              maxLength="5"
              onChange={e => {
                Disable(e.target.value);
                setValue1(formatValor(e.target.value));
              }}
              value={value1}
            />
            e
            <s.Input
              id="second-value-input-dolar"
              maxLength="5"
              onChange={e => {
                Disable(e.target.value);
                setValue2(formatValor(e.target.value));
              }}
              value={value2}
            />
          </s.LabelModal>
        </s.InputContainer>
        <s.LabelModal>Quanto tempo antes você deseja ser avisado?</s.LabelModal>
        <s.InputRadioContainer>
          <s.LabelModal className="f16" flex>
            1h
            <s.InputRadio
              id="input-radio-alerta-1h"
              name="radio"
              value="1h"
              type="radio"
              defaultChecked={true}
            />
          </s.LabelModal>
          <s.LabelModal className="f16" flex>
            2h
            <s.InputRadio
              id="input-radio-alerta-2h"
              name="radio"
              value="2h"
              type="radio"
            />
          </s.LabelModal>
          <s.LabelModal className="f16" flex>
            1D
            <s.InputRadio
              id="input-radio-alerta-1D"
              name="radio"
              value="1D"
              type="radio"
            />
          </s.LabelModal>
        </s.InputRadioContainer>
        <s.ButtonModal>
          <Button
            id="btn-adicionar-alerta"
            text={"Criar Alerta"}
            loading={loading}
            disabled={isDisable ? true : false}
            border={"blue"}
            color={isDisable ? null : "white"}
            filled={isDisable ? false : "blue"}
            onClick={() => {
              return (
                setLoading(true),
                setTimeout(() => {
                  setLoading(false);
                  setSucess(true);
                }, 3000)
              );
            }}
          />
        </s.ButtonModal>
      </ModalContainer>
    );
  } else {
    return (
      <ModalContainer
        isOpen={sucess}
        closeModal={props.closeModal}
        text={"sucesso! seu alerta foi criado."}
      >
        <s.LabelModal className="f16">
          Você será avisado quando a previsão chegar ao valor pretendido e
          também na hora exata em que a taxa real do Dólar chegar ao que você
          espera.
        </s.LabelModal>
        <s.ButtonModal>
          <Button
            id="close-modal-alerta-criado"
            text={"OK"}
            border="blue"
            color="blue"
            onClick={props.closeModal}
          />
        </s.ButtonModal>
      </ModalContainer>
    );
  }
};

export default AlertaModal;

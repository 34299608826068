import styled from "styled-components";
import { theme } from "../../../assets";

export const InputContainer = styled.div`
  text-align: center;
  align-items: center;
  margin: 40px;
  padding-bottom: 35px;
  border-bottom: 1px solid #c8c8c8;
`;

export const Input = styled.input`
  height: 25px;
  width: 117px;
  padding: 4px 4px 4px 8px;
  background: #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1), -1px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  margin: 5px;
  background-color: ${theme.bgDarkGrey};
  color: #fff;
`;

export const LabelModal = styled.div`
  display: ${props => (props.flex ? "flex" : null)};
  text-align: center;
  margin: 15px;
  color: #acb5bd;
`;

export const ButtonModal = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 40px auto;
`;

export const InputRadioContainer = styled.div`
  display: flex;
  margin: 10px 100px;
  justify-content: space-between;
`;

export const InputRadio = styled.input`
  margin: 2px 5px 0px 5px;
  width: 20px;
  height: 20px;
  background: #000;
`;

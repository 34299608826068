const mock = {
  notification: [
    {
      label: "Hoje é a menor cotação do Dólar nos últimos 15 dias.",
      user: 1
    },
    {
      label: "Alerta sobre o valor do Dólar",
      user: 2
    }
  ],
  values: [
    { value: 4.039, time: "09h10" },
    { value: 4.03, time: "11h45" },
    { value: 4.174, time: "09h10" },
    { value: 4.152, time: "16h03" },
    { value: 4.162, time: "13h20" },
    { value: 4.173, time: "15h35" }
  ],
  percentage: [
    {
      value: 0.53,
      order: "up"
    },
    {
      value: 0.27,
      order: "down"
    }
  ],
  options: [
    "Acessar conta na Plataforma ALG",
    "Enviar Feedback",
    "Obter ajuda",
    "Logout"
  ]
};

export default mock;

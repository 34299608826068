import styled from "styled-components";
import { theme } from "../../../assets";

export const LabelModal = styled.div`
  display: ${props => (props.flex ? "flex" : null)};
  text-align: center;
  margin: 5px;
  color: #acb5bd;
`;

export const TextArea = styled.textarea`
  justify-content: center;
  border: 1px solid #000000;
  border-radius: 10px;
  outline: none;
  background: #ffffff;
  width: 340px;
  margin: 5px 0px 5px 55px;
  background-color: ${theme.bgDarkGrey};
  border-color: #acb5bd;
  color: #fff;
`;

export const InputRadio = styled.input`
  margin: 2px 5px 0px 5px;
  width: 20px;
  height: 20px;
`;

export const InputRadioContainer = styled.div`
  display: flex;
  margin: 10px 100px;
  justify-content: space-between;
`;

export const ButtonModal = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 30px auto;
`;

import styled from "styled-components";
import { theme } from "../../assets";

export const Cell = styled.div`
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: ${theme.bgDarkGrey};
`;

export default [
  {
    name: "Data",
    selector: "data",
    style: {
      borderRight: `1px solid ${theme.grayish}`,
      color: "white",
      background: theme.bgDarkGrey
    }
  },
  {
    name: "Hora",
    selector: "content",
    style: {
      borderRight: `1px solid ${theme.grayish}`,
      color: "white",
      background: theme.bgDarkGrey
    }
  },
  {
    name: "Cotação",
    selector: "valorAtual",
    style: {
      borderRight: `1px solid ${theme.grayish}`,
      color: theme.white,
      background: theme.bgDarkGrey
    }
  },
  {
    name: "Melhor Previsão",
    selector: "previsto",
    style: {
      borderRight: `1px solid ${theme.grayish}`,
      color: theme.blue,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: theme.bgDarkGrey
    },
    width: "150px"
  },
  {
    name: "Alta 1",
    selector: "sombra95H",
    style: {
      borderRight: `1px solid ${theme.grayish}`,
      color: theme.green,
      background: theme.bgDarkGrey
    }
  },
  {
    name: "Alta 2",
    selector: "sombra80H",
    style: {
      borderRight: `1px solid ${theme.grayish}`,
      color: "#B787CD",
      background: theme.bgDarkGrey
    }
  },
  {
    name: "Baixa 1",
    selector: "sombra80L",
    style: {
      borderRight: `1px solid ${theme.grayish}`,
      color: theme.yellow,
      background: theme.bgDarkGrey
    }
  },
  {
    name: "Baixa  2",
    selector: "sombra95L",
    style: {
      color: theme.red,
      background: theme.bgDarkGrey
    }
  }
];

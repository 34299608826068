import { createTypes } from "reduxsauce";
import { graphActions } from "./graph.actions";

export const appTypes = createTypes(
  `
    SET_INITIALIZED
    `,
  { prefix: "APP/" }
);
const initialize = () => {
  return async dispatch => {
    dispatch(graphActions.setSelected("30min"));
    dispatch({
      type: appTypes.SET_INITIALIZED,
      initialized: true
    });
  };
};

export const appActions = {
  initialize
};

import styled from "styled-components";
import Expand from "react-expand-animated";
import { theme } from "../../assets";

export const HeaderContainer = styled.div`
  background: ${theme.bgBlack};
`;

export const TopHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
`;

export const BottomHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.size === true ? "column" : "row")};
  justify-content: space-between;
  margin: 15px 0px 10px 0px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => (props.width ? "100%" : null)};
  background: ${props => (props.bg ? theme.bgDarkGrey : null)};
`;

export const AlertContainer = styled.div`
  padding: 5px 20px;
`;

// const [headers, setHeaders] = useState(mock.headers);
export const NotificationContainer = styled.div`
  padding: 10px 30px;
  margin: 0px 20px;
  border-left: 1px solid ${theme.bgDarkGrey};
  border-right: 1px solid ${theme.bgDarkGrey};
`;

export const PersonContainer = styled.div`
  padding: 10px 20px;
`;

export const Image = styled.img`
  width: 32px;
  display: flex;
  margin: auto;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
    transition: all 0.2s;
  }
`;

export const InfoContainer = styled.div`
  display: grid;
  margin: 8px auto 0px 15px;
`;

export const CotacaoContainer = styled.div`
  display: flex;
`;

export const Cotacao = styled.label`
  line-height: 25px;
  margin-right: 5px;
  font-weight: ${props => (props.bold ? "bold" : null)};
  color: ${props => (props.color === "grey" ? "#ACB5BD" : "#fff")};
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const Mercado = styled.label`
  display: flex;
  color: ${props => (props.closed === "closed" ? theme.red : theme.green)};
`;

export const Date = styled.label`
  margin-left: 5px;
  color: ${props => (props.color === "grey" ? "#ACB5BD" : "#fff")};
`;

export const UserExpand = styled(Expand)`
  z-index: 1;
  position: absolute;
  top: 60px;
  max-height: 250px;
  border-radius: 0 0 4px 4px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
  background: ${theme.bgDarkGrey};
  right: 45px;
  z-index: 2;
`;

export const NotificationExpand = styled(Expand)`
  z-index: 1;
  position: absolute;
  top: 60px;
  max-height: 250px;
  border-radius: 0 0 4px 4px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  background: ${theme.bgDarkGrey};
  z-index: 2;
`;

export const Notification = styled.div`
  width: 300px;
  padding: 20px;
  border-bottom: 1px solid #282828;
  color: #acb5bd;
  :hover {
    color: #fff;
  }
`;

export const OptionsContainer = styled.div`
  display: grid;
  margin: 20px 5px;
`;

export const Options = styled.label`
  margin: 8px;
  padding: 5px;
  line-height: 27px;
  font-weight: ${props => (props.bold ? "bold" : "normal")};
  cursor: pointer;
  color: #acb5bd;
  :hover {
    color: #fff;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  width: calc(20% - 30px);
  margin: 0px 15px;
`;

export const DisplayValues = styled.div`
  display: flex;
  width: calc(100% - 4px);
  margin: 5px 2px;
`;

export const Value = styled.label`
  font-size: 30px;
  font-weight: bold;
  margin: 0px 10px 0px 10px;
  color: #fff;
`;

export const RegisterValuesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const PercentageDirection = styled.img`
  margin: 5px;
`;

export const Percentage = styled.label`
  display: flex;
  color: ${props => (props.up ? "#65d4ef" : "#E87364")};
  margin: auto 0px auto 0px;
`;

export const MinMaxContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.screen <= 768 ? "column" : "row")};
  justify-content: space-around;
`;

export const MinMaxLabelContainer = styled.div`
  margin-left: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MinMaxValues = styled.label`
  display: flex;
  flex-direction: column;
  color: ${props => (props.red ? theme.red : theme.blue)};
  text-align: center;
  font-weight: bold;
`;

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const View = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ViewLabel = styled.label`
  ${props => props.right && `margin-left: 15px;`}
  width: 125px;
  vertical-align: center;
`;

export const SelectedViewContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 8px;
  margin-left: 15px;
  background: ${props => (props.selected ? "#65D4EF" : "#C8C8C8")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SelectedView = styled.img`
  width: 25px;
`;

export const DivLista = styled.div`
  width: 100%;
  height: 300px;
  background: lightblue;
`;

export const DivGrafico = styled.div`
  width: 100%;
  height: 300px;
  background: lightgreen;
`;

export const Label = styled.label`
  font-size: 14px;
  text-align: center;
  color: ${props =>
    props.color === "grey"
      ? "#ACB5BD"
      : props.color === "red"
      ? theme.red
      : theme.blue};
`;

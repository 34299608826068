import React, { useState } from "react";
import { ModalContainer, Button } from "../../index";
import { icons } from "../../../assets";
import * as s from "./styled-feedback";

const FeedbackModal = props => {
  const [sucess, setSucess] = useState(false);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const scoreValue = [1, 2, 3, 4, 5];

  const Disable = e => {
    if (e === "") {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };

  if (!sucess) {
    return (
      <ModalContainer
        isOpen={props.isOpen}
        closeModal={props.closeModal}
        text={"que nota você atribui a este produto?"}
      >
        <s.NotaContainer>
          {scoreValue.map((data, index) => {
            return (
              <s.Nota
                className="f18"
                key={index}
                id="feedback-score"
                clicked={data === score}
                onClick={() => {
                  setScore(data);
                }}
              >
                {data}

                {data === score && <s.CheckNota src={icons.check} />}
              </s.Nota>
            );
          })}
        </s.NotaContainer>
        <s.LabelModal className="f16">
          Compartilhe a sua opinião conosco!
        </s.LabelModal>
        <s.TextArea
          className="f12"
          id="textarea-feedback"
          rows={10}
          onChange={e => Disable(e.target.value)}
        />
        <s.ButtonModal>
          <Button
            id="btn-enviar-feedback"
            text={"Enviar"}
            loading={loading}
            border={"blue"}
            disabled={isDisable ? true : false}
            color={isDisable ? "blue" : null}
            filled={isDisable ? false : "blue"}
            onClick={() => {
              return (
                setLoading(true),
                setTimeout(() => {
                  setLoading(false);
                  setSucess(true);
                }, 3000)
              );
            }}
          />
        </s.ButtonModal>
      </ModalContainer>
    );
  } else {
    return (
      <ModalContainer
        isOpen={sucess}
        closeModal={props.closeModal}
        text={"UHU! RECEBEMOS O SEU FEEDBACK!"}
      >
        <s.LabelModal className="f16">
          Obrigado por compartilhar a sua opinião
        </s.LabelModal>
        <s.LabelModal className="f16">
          Caso seja necessário, retornaremos o contato
        </s.LabelModal>
      </ModalContainer>
    );
  }
};

export default FeedbackModal;

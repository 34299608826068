import React, { useState, useEffect, useLayoutEffect } from "react";
import * as s from "./styled-footer";
import mock from "./mock";
import { graphActions } from "../../store/actions/graph.actions";
import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
  const [selectedView, setSelectedView] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState("1D");
  const [size, setSize] = useState("");
  const dispatch = useDispatch();
  const evidence = useSelector(state => state.graph.evidence);
  const [selectedPrevision, setSelectedPrevision] = useState("30min");

  useEffect(() => {
    if (selectedView === false) {
      dispatch(graphActions.setSelected(selectedPeriod));
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedView === false) {
      dispatch(graphActions.setSelected(selectedPeriod));
    }
  }, [selectedView]);

  useLayoutEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const updateSize = () => {
    setSize(window.screen.width);
  };

  return (
    <s.FooterContainer size={size}>
      <s.ViewContainer>
        <s.ViewContentContainer>
          <s.Label
            id="label-intraday"
            className="f16"
            onClick={() => {
              setSelectedView(true);
              setSelectedPrevision("30min");
              dispatch(graphActions.setSelected("30min"));
            }}
            selected={selectedView}
            color={selectedView ? "true" : "false"}
          >
            Intraday
          </s.Label>
          <s.Label
            id="label-historico"
            className="f16"
            onClick={() => {
              // setSelectedView(false);
              // dispatch(graphActions.setSelected(""));
              // setSelectedPrevision("");
              // checkView();
            }}
            selected={!selectedView}
            color={!selectedView ? "true" : "false"}
            style={{ cursor: "not-allowed " }}
          >
            Histórico
          </s.Label>
        </s.ViewContentContainer>
        <s.HistoricOptions>
          {selectedView === false &&
            mock.view.map((data, index) => {
              return (
                <s.Label
                  id="historico-options"
                  className="f16"
                  key={index}
                  selected={data === selectedPeriod}
                  onClick={() => {
                    setSelectedPeriod(data);
                    setSelectedPrevision("");
                  }}
                  color={data === selectedPeriod ? "true" : "false"}
                >
                  {data}
                </s.Label>
              );
            })}
        </s.HistoricOptions>
      </s.ViewContainer>
      <s.PrevisionContainer>
        <s.Label style={{ marginBottom: "8px" }}>Previsões Futuras</s.Label>
        <s.ViewContentContainer margin>
          {mock.prevision.map((data, index) => {
            return (
              <s.Label
                id="previsao-futura-option"
                className="f16"
                key={index}
                selected={data === selectedPrevision}
                onClick={() => {
                  setSelectedView(true);
                  setSelectedPrevision(data);
                  dispatch(graphActions.setSelected(data));
                }}
                color={data === selectedPrevision ? "true" : "false"}
              >
                {data}
              </s.Label>
            );
          })}
        </s.ViewContentContainer>
      </s.PrevisionContainer>
      <s.InfoContainer>
        <s.Values>
          <s.Label className="f16">Melhor Previsão</s.Label>
          <s.Options>
            <s.Evidence
              id="select-option-best-prevision"
              selected={evidence === 0}
              blue
              onClick={() => dispatch(graphActions.setEvidence(0))}
            />
          </s.Options>
        </s.Values>
        <s.Values>
          <s.Label>Outras Possibilidades</s.Label>
          <s.Options>
            <s.Evidence
              id="select-option-95%-1"
              selected={evidence === 1}
              green
              onClick={() => dispatch(graphActions.setEvidence(1))}
            />
            <s.Label className={"f16"}>95%</s.Label>
            <s.Evidence
              id="select-option-80%-1"
              selected={evidence === 2}
              purple
              onClick={() => dispatch(graphActions.setEvidence(2))}
            />
            <s.Label className={"f16"}> 80% </s.Label>
            <s.Evidence
              id="select-option-80%-2"
              selected={evidence === 3}
              orange
              onClick={() => dispatch(graphActions.setEvidence(3))}
            />
            <s.Label className={"f16"}> 80%</s.Label>
            <s.Evidence
              id="select-option-95%-2"
              selected={evidence === 4}
              red
              onClick={() => dispatch(graphActions.setEvidence(4))}
            />
            <s.Label className={"f16"}> 95%</s.Label>
          </s.Options>
        </s.Values>
      </s.InfoContainer>
    </s.FooterContainer>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Dashboard } from "./screens";
import GlobalStyle from "./globalStyle";
import { useDispatch } from "react-redux";
import { appActions } from "./store/actions";
import { firebase } from "./utils";

function App() {
  const dispatch = useDispatch();
  firebase.getPrevisao30minRealtime();
  firebase.getPrevisao1dRealtime();
  firebase.getPrevisao1sRealtime();

  useEffect(() => {
    dispatch(appActions.initialize());
  }, []);

  return (
    <div style={{ flex: 1, height: "100vh" }}>
      <GlobalStyle />
      <Switch>
        <Route path="/dashboard" render={() => <Dashboard />} />
        <Redirect to="/dashboard" />
      </Switch>
    </div>
  );
}

export default App;

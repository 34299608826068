import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import * as s from "./styled-header";
import {
  Button,
  Grafico,
  Table,
  AlertaModal,
  FeedbackModal,
  AjudaModal,
  LogoutModal,
  Loader
} from "../../components";
import { icons } from "../../assets";
import moment from "moment";
import mock from "./mock";
import "moment/locale/pt-br";
import { useSelector } from "react-redux";

const Header = () => {
  moment.locale("pt-br");
  const UserRef = useRef("user");
  const NotificationRef = useRef("notification");
  const [openUser, setOpenUser] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState("");
  const [selected, setSelected] = useState("Grafico");
  const [size, setSize] = useState("");
  const [porcentagem, setPorcentagem] = useState(0);
  const data = useSelector(state => state.graph.graphData30min);

  let date = new Date();

  useLayoutEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    getValue();
  }, []);

  useEffect(() => {
    if (data.previsao) {
      setPorcentagem(
        ((data.ultimaCotacao / data.ultimoFechamento - 1) * 100).toFixed(2)
      );
    }
  }, [data.ultimaCotacao]);

  const updateSize = () => {
    setSize(window.innerWidth);
  };

  const handleClickOutside = e => {
    if (UserRef.current.contains(e.target)) {
      setOpenNotification(false);
    } else if (NotificationRef.current.contains(e.target)) {
      setOpenUser(false);
    } else {
      setOpenNotification(false);
      setOpenUser(false);
    }
  };

  const User = () => {
    return (
      <s.UserExpand open={openUser}>
        <s.OptionsContainer>
          {mock.options.map((dados, index) => {
            return (
              <s.Options
                className="f16"
                id="options-user"
                key={index}
                onClick={() => {
                  setType(dados);
                  setIsOpen(true);
                }}
              >
                {dados}
              </s.Options>
            );
          })}
        </s.OptionsContainer>
      </s.UserExpand>
    );
  };

  const Notification = () => {
    return (
      <s.NotificationExpand open={openNotification}>
        {mock.notification.map((dados, index) => {
          return (
            <s.Notification className="f16" key={index}>
              {dados.label}
            </s.Notification>
          );
        })}
      </s.NotificationExpand>
    );
  };

  const renderContent = () => {
    switch (type) {
      case "alerta":
        return (
          <AlertaModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
        );
      case "Enviar Feedback":
        return (
          <FeedbackModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
        );
      case "Obter ajuda":
        return (
          <AjudaModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
        );
      case "Logout":
        return (
          <LogoutModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
        );
      default:
        break;
    }
  };

  const getValue = type => {
    if (data.previsao) {
      if (type === "RegisterMin") {
        let lower = 9999;
        data.previsao.map(data => {
          if (data.valorAtual < lower && data.valorAtual !== null) {
            lower = data.valorAtual;
          }
        });
        return lower;
      }
      if (type === "RegisterMax") {
        let higher = 0;
        data.previsao.map(data => {
          if (data.valorAtual > higher && data.valorAtual !== null) {
            higher = data.valorAtual;
          }
        });
        return higher;
      }
      if (type === "MinP") {
        let lower = 9999;
        data.previsao.map(data => {
          if (data.sombra95L < lower && data.sombra95L !== null) {
            lower = data.sombra95L;
          }
        });
        return lower;
      }
      if (type === "MaxP") {
        let higher = 0;
        data.previsao.map(data => {
          if (data.sombra95H > higher && data.sombra95H !== null) {
            higher = data.sombra95H;
          }
        });
        return higher;
      }
    }
  };

  return (
    <>
      {isOpen && renderContent()}
      <s.HeaderContainer>
        <s.TopHeader>
          <s.InfoContainer>
            <s.CotacaoContainer>
              <s.Cotacao bold>Resumo do dia | </s.Cotacao>
              <s.Cotacao className="f14" color="grey">
                COTAÇÃO ATUAL USD / BRL
              </s.Cotacao>
            </s.CotacaoContainer>
            <s.DateContainer>
              <s.Mercado
                className="f14"
                closed={
                  moment().format("HH") < 18 && moment().format("HH") > 9
                    ? "open"
                    : "closed"
                }
              >
                {moment().format("HH") < 18 && moment().format("HH") > 9
                  ? "MERCADO ABERTO"
                  : "MERCADO FECHADO"}
              </s.Mercado>
              <s.Date className="f14" color="grey">
                ({moment(date).format("DD/MM")} / {moment().format("LT")})
              </s.Date>
            </s.DateContainer>
          </s.InfoContainer>
          <s.ButtonContainer>
            <s.AlertContainer>
              <Button
                id="btn-header-alerta"
                text={"+ Criar Alerta"}
                onClick={() => {
                  setType("alerta");
                  setIsOpen(true);
                }}
                filled={"red"}
              />
            </s.AlertContainer>
            <s.NotificationContainer>
              <s.Image
                id="open-notification"
                ref={NotificationRef}
                src={icons.notificacao}
                onClick={() => {
                  setOpenNotification(!openNotification);
                }}
              />
            </s.NotificationContainer>
            {Notification()}
            <s.PersonContainer>
              <s.Image
                id="open-user"
                ref={UserRef}
                src={icons.person}
                onClick={() => setOpenUser(!openUser)}
              />
            </s.PersonContainer>
            {User()}
          </s.ButtonContainer>
        </s.TopHeader>
        <s.BottomHeader size={size >= 1020 ? "false" : "true"}>
          <s.ValueContainer>
            <s.DisplayValues>
              <s.Value>{data.ultimaCotacao}</s.Value>
              <s.Percentage className="f16" up={porcentagem < 0 ? false : true}>
                <s.PercentageDirection
                  src={porcentagem < 0 ? icons.arrowDown : icons.arrowUp}
                />
                {data.previsao && porcentagem}%
              </s.Percentage>
            </s.DisplayValues>
          </s.ValueContainer>
          <s.RegisterValuesContainer>
            <s.MinMaxLabelContainer>
              <s.Label className="f14" color="grey">
                MÍNIMA REGISTRADA
              </s.Label>
              <s.MinMaxValues red className="f18">
                {data.previsao ? getValue("RegisterMin") : <Loader />}
              </s.MinMaxValues>
            </s.MinMaxLabelContainer>

            <s.MinMaxLabelContainer>
              <s.Label className="f14" color="grey">
                MÁXIMA REGISTRADA
              </s.Label>
              <s.MinMaxValues className="f18">
                {data.previsao ? getValue("RegisterMax") : <Loader />}
              </s.MinMaxValues>
            </s.MinMaxLabelContainer>

            <s.MinMaxLabelContainer>
              <s.Label className="f14" color="grey">
                MÍNIMA PREVISTA
              </s.Label>
              <s.MinMaxValues red className="f18">
                <s.Label className="f12" color="grey">
                  Próx. 30min
                </s.Label>
                {data.previsao ? getValue("MinP") : <Loader />}
              </s.MinMaxValues>
            </s.MinMaxLabelContainer>
            <s.MinMaxLabelContainer>
              <s.Label className="f14" color="grey">
                MÁXIMA PREVISTA
              </s.Label>
              <s.MinMaxValues className="f18">
                <s.Label className="f12" color="grey">
                  Próx. 30min
                </s.Label>
                {data.previsao ? getValue("MaxP") : <Loader />}
              </s.MinMaxValues>
            </s.MinMaxLabelContainer>
          </s.RegisterValuesContainer>
        </s.BottomHeader>
        <s.ButtonContainer width={"true"} bg>
          <Button
            style={{ marginLeft: "20px" }}
            id="select-graph-view"
            text="Exibir em gráfico"
            onClick={() => setSelected("Grafico")}
            filled={selected === "Grafico" ? "blue" : null}
            border={"blue"}
            color={selected === "Grafico" ? "white" : "blue"}
          />
          <Button
            id="select-list-view"
            text="Exibir em lista"
            onClick={() => setSelected("Lista")}
            filled={selected === "Lista" ? "blue" : null}
            border={"blue"}
            color={selected === "Lista" ? "white" : "blue"}
          />
        </s.ButtonContainer>
      </s.HeaderContainer>
      {selected === "Grafico" ? <Grafico /> : <Table />}
    </>
  );
};

export default Header;

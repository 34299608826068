import React, { useState } from "react";
import { ModalContainer, Button } from "../../index";
import * as s from "./styled-ajuda";

const AjudaModal = props => {
  const [sucess, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  const Disable = e => {
    if (e === "") {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };

  if (!sucess) {
    return (
      <ModalContainer
        isOpen={props.isOpen}
        closeModal={props.closeModal}
        text={"como podemos te ajudar?"}
      >
        <s.LabelModal className="f16">
          Escreva a sua dúvida aqui e iremos retornar o contato para lhe
          auxiliar!
        </s.LabelModal>
        <s.TextArea
          id="textarea-ajuda"
          className="f12"
          rows={11}
          onChange={e => Disable(e.target.value)}
        />
        <s.LabelModal className="f16">
          Como você prefere receber nosso contato?
        </s.LabelModal>
        <s.InputRadioContainer>
          <s.LabelModal className="f16" flex>
            E-mail
            <s.InputRadio
              id="input-radio-email"
              name="radio"
              value="email"
              type={"radio"}
              defaultChecked={true}
            />
          </s.LabelModal>
          <s.LabelModal className="f16" flex>
            Telefone
            <s.InputRadio
              id="input-radio-telefone"
              name="radio"
              value="telefone"
              type={"radio"}
            />
          </s.LabelModal>
        </s.InputRadioContainer>
        <s.ButtonModal>
          <Button
            id="btn-ajuda"
            text={"Enviar"}
            loading={loading}
            disabled={isDisable ? true : false}
            border={"blue"}
            color={isDisable ? "blue" : "white"}
            filled={isDisable ? false : "blue"}
            onClick={() => {
              return (
                setLoading(true),
                setTimeout(() => {
                  setLoading(false);
                  setSucess(true);
                }, 3000)
              );
            }}
          />
        </s.ButtonModal>
      </ModalContainer>
    );
  } else {
    return (
      <ModalContainer
        isOpen={sucess}
        closeModal={props.closeModal}
        text={"fique tranquilo, recebemos sua solicitação"}
      >
        <s.LabelModal className="f16">
          Retornaremos em breve para resolvê-la :)
        </s.LabelModal>
      </ModalContainer>
    );
  }
};

export default AjudaModal;

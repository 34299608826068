import styled from "styled-components";

export const LabelModal = styled.div`
  text-align: center;
  margin: 5px;
  color: #acb5bd;
`;

export const LogOutButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 5px;
`;

import React from "react";

const Loader = () => {
  return (
    <>
      <div
        className="spinner"
        style={{
          backgroundColor: "transparent",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </>
  );
};
export default Loader;

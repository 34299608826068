import { createGlobalStyle } from "styled-components";
import { theme } from "./assets";

export default createGlobalStyle`
    * {
        padding: 0px; margin: 0px;
        font-family: Nunito;
    };

    label.f12 { font-size: 12px }
    label.f14 { font-size: 14px }
    label.f15 { font-size: 15px }
    label.f16 { font-size: 16px }
    label.f18 { font-size: 18px }

    textarea {
        resize: none;
    }

    .rdt_TableRow {
        border-bottom: 1px;
        font-size: calc(0.4vw + 10px);
    }

    .rdt_TableCell {
        padding: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .rdt_TableCol {
        border: solid ${theme.grayish};
        border-width: 0px 1px 0px 0px;
        padding: 0;
        font-style: normal;
        font-weight: bold;
        font-size: calc(0.2vw + 10px);
        text-transform: uppercase;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .rdt_TableHead {
        border-top: 1px solid ${theme.grayish};
        width: calc(100% - 15px);
    }


    .rdt_TableBody {
        height: 50vh
    }
@media (min-width: 1367px){
.rdt_TableBody {
        height: 60vh
    }
}
@media (max-width: 1365px) and (min-width: 1024px){
.rdt_TableBody {
        height: 60vh
    }
}
@media (width: 1366px){
.rdt_TableBody {
        height: 45vh
    }
}
/* @media (min-width: 1024px) and (max-width: 1366px){
.rdt_TableBody {
        height: 60vh
    }
} */
/* @media (min-width: 1024px) and (max-width: 1366px ){
.rdt_TableBody {
        height: 60vh
    }
} */


    @media(max-width: 905px) {
        .rdt_TableRow .rdt_TableCol {
            font-size: 12px;
        }
    }

    .spinner {
        width: 70px;
        text-align: left;
        vertical-align: baseline;
        display: flex;
        align-items: flex-end;
        height: 15px;
        margin-left: 4px;
    }

    .spinner > div {
        width: 12px;
        height: 12px;
        vertical-align: baseline;

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
        background: #fff
    }

    .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
        background: #fff
    }

    .spinner .bounce3 {
        background: #fff
    }

    .rising {
        animation: rising 1.4 infinite;
        animation-delay: -0.16s;
        -webkit-animation: rising 1.4s infinite;
    }

    @keyframes rising {
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { -webkit-transform: scale(0) }
        40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
        0%, 80%, 100% { 
          -webkit-transform: scale(0);
          transform: scale(0.5);
          margin-right: 0px;
        } 40% { 
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
          margin-right: 1px;
        }
    }

    label, a, span, h1, h2, h3, h4 {
        font-family: Nunito;
      }
`;
